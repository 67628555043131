import React from "react"
import Header from "./../components/header"
import { graphql } from "gatsby"
import Layout from "../components/layout"

interface IProps {
  data: {
    site: {
      siteMetadata: {
        title: string,
      },
    },
  }
}

export default ({ data }: IProps) => {
  return (
    <Layout>
      <Header headerText={data.site.siteMetadata.title} />
      <p>Ofc</p>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
